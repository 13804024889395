import React, { FC, MouseEvent, useState } from "react";
import { useSelector } from "react-redux";
import c from "classnames";

import SettingsIcon from "@mui/icons-material/Settings";
import RefreshIcon from "@mui/icons-material/Refresh";

import { Tooltip } from "../../../../Components";
import { SkillGroupData } from "../../Monitoring";
import * as selector from "../../Store/Monitoring.selector";
import hasUserRight from "../../../../Utils/hasUserRight";

import { StatsQueue, Subscription } from "../index";

import * as s from "./styles.module.scss";

interface SkillGroupProps {
  current: boolean;
  skillgroup: SkillGroupData;
  onSelect?: (id: number) => void;
  onManageLoad?: (id: number) => void;
  agentSelected?: boolean;
  appealsLimit?: number;
  timeMax?: string;
  editable?: boolean;
}

const SkillGroupItem: FC<SkillGroupProps> = ({
  editable,
  current,
  skillgroup: { id, name, agentsCount, appealsCount, appealsLimit, timeMax },
  onSelect,
  onManageLoad
}) => {
  const [isHovered, setHovered] = useState(false);
  const handleMouseOver = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  const loadingAgents = useSelector(selector.loadingStatus).includes(
    "monitoring/GET_AGENTS"
  );

  const handleEdit = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (id) onManageLoad(id);
  };

  const handleSelect = () => {
    if (id && !current) onSelect(id);
  };

  return (
    <div
      id={`${id}`}
      onClick={handleSelect}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      className={c(s["skillgroupWrap"], {
        [s["current"]]: current
      })}
    >
      <div className={c(s["skillgroupItem"], s["clearFix"])}>
        {id && (
          <StatsQueue
            type="group"
            id={id}
            appealsCount={appealsCount}
            appealsLimit={appealsLimit}
            timeMax={timeMax}
            isHovered={isHovered}
          />
        )}

        <div
          id={`skillgroup-name-${id}`}
          className={c(s["skillgroupName"], {
            [s["hover"]]: isHovered
          })}
        >
          <Tooltip content={name} enterDelay={1000} followCursor>
            <span>{name}</span>
          </Tooltip>
        </div>

        <div className={c(s["skillgroupInfo"], s["clearFix"])}>
          <Subscription
            id={`${id}`}
            appealsCount={appealsCount}
            agentsCount={agentsCount || 0}
          />
        </div>

        {editable && hasUserRight("skill_limit") && id && (
          <div
            id={`manage-skillgroup-${id}`}
            onClick={handleEdit}
            className={s["skillgroupIcon"]}
          >
            {loadingAgents ? (
              <RefreshIcon />
            ) : (
              <SettingsIcon id={`skillgroup-icon-${id}`} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SkillGroupItem;
