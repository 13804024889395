import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "../../../ReactCore/store/types";

export const agentsState = (state: StoreState.State) => {
  return state.Monitoring;
};

export const agentList = createSelector(agentsState, (state) => {
  return state.agentList;
});

export const agentData = createSelector(agentsState, (state) => {
  return state.agentData;
});

export const getQuery = createSelector(agentsState, (state) => {
  return state.query;
});

export const getPageAgents = createSelector(agentsState, (state) => {
  return +state.currentAgentsPage;
});

export const skillGroupList = createSelector(agentsState, (state) => {
  return state.skillGroupList;
});

export const skillGroupListIds = createSelector(skillGroupList, (state) => {
  return state.map(({ id }) => id);
});

export const skillGroupData = createSelector(agentsState, (state) => {
  return state.skillGroupData;
});

export const commonActiveCounter = createSelector(
  agentsState,
  ({ monitoringOverall }) => {
    return {
      appealsCount: monitoringOverall?.appeals || 0,
      agentsCount: monitoringOverall?.agents_online || 0
    };
  }
);

export const monitoringAgents = createSelector(agentsState, (state) => {
  return state.monitoringAgents;
});

export const monitoringGroups = createSelector(agentsState, (state) => {
  return state.monitoringGroups;
});

export const loadingStatus = createSelector(agentsState, (state) => {
  return state.loading;
});

export const agentsWithMonitoring = (currentGroupId: number) =>
  createSelector(
    [getQuery, agentList, monitoringAgents],
    (query, allAgents, monitoringAgents) => {
      if (!currentGroupId) return [];

      if (!query) {
        const sortedTopAgents = [] as typeof allAgents;
        for (let monitoringAgentId in monitoringAgents) {
          const agent = monitoringAgents[monitoringAgentId];
          const isBot = agent.status === null;
          const agentInSelectedGroup = agent.groups.includes(currentGroupId);

          if (!isBot && agentInSelectedGroup) {
            sortedTopAgents.push({
              id: +monitoringAgentId,
              name: agent.name,
              secName: agent.sec_name,
              photo: agent.photo,
              networkStatus: agent.status,
              skillGroups: agent.groups,
              appealsCount: agent.appeals || 0,
              appealsCountByGroup: agent.appeals_by_group?.[currentGroupId] || 0,
              appealsLimit: agent.appeals_limit
            });
          }
        }
        return [
          ...sortedTopAgents.sort((a, b) => a.networkStatus - b.networkStatus),
          ...allAgents.filter((a) => !monitoringAgents?.[a.id])
        ];
      } else {
        return allAgents.map((agent) => {
          const monitoringData = monitoringAgents[agent.id];
          return {
            ...agent,
            ...(monitoringData && {
              appealsCount:
                monitoringData.appeals_by_group?.[currentGroupId] || 0,
              appealsLimit: monitoringData.appeals_limit,
              networkStatus: monitoringData.status
            })
          };
        });
      }
    }
  );

export const skillGroupsWithMonitoring = createSelector(
  [skillGroupList, monitoringGroups],
  (allGroups, monitoringGroups) => {
    const sortedTopGroups = [] as typeof allGroups;
    for (let id in monitoringGroups) {
      const group = allGroups.find((g) => g.id === +id);
      const activeGroup = monitoringGroups[id];
      sortedTopGroups.push({
        ...group,
        appealsCount: activeGroup.appeals,
        agentsCount: activeGroup.agents_online,
        appealsLimit: activeGroup.queued.limit,
        timeMax: activeGroup.queued.time_max
      });
    }

    return [
      ...sortedTopGroups.sort((a, b) => b.agentsCount - a.agentsCount),
      ...allGroups.filter((g) => !monitoringGroups?.[g.id])
    ];
  }
);
